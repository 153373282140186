import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';  // Import Axios for file uploads

const VideoRecorder = () => {
    const videoRef = useRef(null);
    const isUserStoppedRef = useRef(false); // UseRef to track user stop
    const allChunksRef = useRef([]); // UseRef to persist chunks across renders
    const [isRecording, setIsRecording] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [downloadUrl, setDownloadUrl] = useState(null);
    const [facingMode, setFacingMode] = useState('user');
    const [stream, setStream] = useState(null);
    const [isStreamStarted, setIsStreamStarted] = useState(false);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [cameraAccessed, setCameraAccessed] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);  // State for upload progress
    const [isPrivate, setIsPrivate] = useState(false);  // State for privacy (checkbox)
    
    const uploadProgressRef = useRef(0);  // useRef to track upload progress without re-rendering
    const [uploadComplete, setUploadComplete] = useState(false);  // State for upload completion
    const [videoInfo, setVideoInfo] = useState({url: null, secureUrl: null, accessMode: null});

    useEffect(() => {
        if (stream && videoRef.current) {
            videoRef.current.srcObject = stream;
            console.log("Stream set for video element:", stream);
        }
    }, [stream]);

    const detectVideoFormat = () => {
        const videoElement = document.createElement('video');
        const userAgent = navigator.userAgent.toLowerCase();
        const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
        const isIOS = /iphone|ipad|ipod/i.test(userAgent);
        if (isSafari || isIOS) {
            return 'mp4';
        }
        return videoElement.canPlayType('video/webm; codecs="vp8, vorbis"') !== '' ? 'webm' : 'mp4';
    };

    const videoFormat = detectVideoFormat();

    const startCameraStream = async (facing) => {
        try {
            console.log(`Starting camera with facingMode: ${facing}`);
            const newStream = await navigator.mediaDevices.getUserMedia({
                video: { facingMode: facing },
                audio: {
                    noiseSuppression: true,
                    echoCancellation: true,
                },
            });

            if (newStream && newStream.getTracks().length > 0) {
                setStream(newStream);
                setIsStreamStarted(true);
                setCameraAccessed(true);
                console.log('Camera accessed, stream started:', newStream);
                console.log('Stream tracks:', newStream.getTracks());
            } else {
                console.error('Stream has no tracks.');
            }
        } catch (err) {
            console.error('Error accessing webcam:', err);
        }
    };

    const startRecording = () => {
        if (!stream || !(stream instanceof MediaStream)) {
            console.error("Stream is not available or not a valid MediaStream for recording.");
            return;
        }

        setPreviewUrl(null);
        isUserStoppedRef.current = false; // Reset the user stop flag
        console.log("Starting new recording session.");

        const recorder = new MediaRecorder(stream);
        console.log("MediaRecorder started with stream:", stream);

        recorder.ondataavailable = (event) => {
            if (event.data && event.data.size > 0) {
                console.log('Before push - allChunks length:', allChunksRef.current.length);
                allChunksRef.current.push(event.data); // Use ref to store chunks across sessions
                console.log('Chunk received, size:', event.data.size);
                console.log('After push - allChunks length:', allChunksRef.current.length);
            }
        };

        recorder.onstop = () => {
            const userStopped = isUserStoppedRef.current; // Capture the latest ref value
            console.log('Recording stopped. Total chunks:', allChunksRef.current.length);
            console.log("isUserStopped at onstop:", userStopped);
            
            if (userStopped) {
                console.log("Recording stopped by user. Preparing preview and download link...");
                if (allChunksRef.current.length > 0) {
                    const blob = createDynamicBlob(allChunksRef.current);
                    const videoUrl = URL.createObjectURL(blob);
                    setDownloadUrl(videoUrl);
                    setPreviewUrl(videoUrl);
                    console.log('Download URL created:', videoUrl);

                    // Call the upload function with the video file and privacy setting
                    uploadVideo(blob);
                } else {
                    console.error('No data available to create a video.');
                }
            } else {
                console.log('Recording stopped, but not by user action (likely a camera flip).');
            }
        };

        recorder.start();
        setMediaRecorder(recorder);
        setIsRecording(true);
        console.log("Recording started...");
    };

    const uploadVideo = (blob) => {
        const reader = new FileReader();

        // Read the file as a base64 string
        reader.readAsDataURL(blob);

        reader.onloadend = () => {
            try {
                // Prepare the data to be sent to the server, including the privacy setting
                const objData = {
                    file: reader.result,  // The base64 encoded file
                    filename: 'recorded_video.mp4',  // You can pass the filename as metadata
                    isPrivate,  // Include the privacy setting in the request data
                };

                // Post the base64-encoded file using Axios
                axios.post(`${window.location.origin}/sapi/upload`, objData, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    onUploadProgress: (progressEvent) => {
                        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);

                        // Store the progress in the useRef
                        uploadProgressRef.current = progress;

                        // Optionally update state if you want to display progress visually
                        setUploadProgress(progress);

                        console.log(`Upload Progress: ${progress}%`);
                    },
                })
                .then((response) => {
                    console.log('Upload complete!', response.data);
                    setVideoInfo(response.data);
                    setUploadComplete(true);  // Notify the user of upload completion
                })
                .catch((error) => {
                    console.error('Upload failed:', error);
                });
            } catch (error) {
                console.error('Error during upload:', error);
            }
        };

        reader.onerror = (error) => {
            console.error('Error reading file:', error);
        };
    };

    const pauseRecording = () => {
        if (mediaRecorder && isRecording && !isPaused) {
            mediaRecorder.pause();
            setIsPaused(true);
            console.log("Recording paused.");
        } else if (mediaRecorder && isPaused) {
            mediaRecorder.resume();
            setIsPaused(false);
            console.log("Recording resumed.");
        }
    };

    const stopRecording = () => {
        if (mediaRecorder) {
            console.log("Setting isUserStopped to true right before stopping the recorder.");
            isUserStoppedRef.current = true; // Set this flag right before stopping using useRef
            mediaRecorder.stop();
            setIsRecording(false);
            setIsPaused(false);
            console.log("Recording stopped by user.");
        }
    };

    const flipCamera = async () => {
        if (stream) {
            try {
                const newFacingMode = facingMode === 'user' ? 'environment' : 'user';
                console.log(`Flipping camera to: ${newFacingMode}`);

                const videoTrack = stream.getVideoTracks()[0];
                const audioTrack = stream.getAudioTracks()[0];

                // Stop the current video track
                videoTrack.stop();
                console.log("Stopped current video track:", videoTrack);

                // Get a new stream with the flipped camera
                const newStream = await navigator.mediaDevices.getUserMedia({
                    video: { facingMode: newFacingMode },
                    audio: false, // Keep the existing audio track
                });

                const newVideoTrack = newStream.getVideoTracks()[0];
                console.log("New video track from flipped camera:", newVideoTrack);

                // Create a new stream with the new video track and old audio track
                const updatedStream = new MediaStream([audioTrack, newVideoTrack]);

                // Stop the current MediaRecorder and save the current chunks
                if (mediaRecorder) {
                    console.log('Stopping MediaRecorder temporarily to update video track...');
                    mediaRecorder.stop(); // Stop the recording temporarily
                }

                // Update the stream used by the video element
                setStream(updatedStream);
                console.log("Updated stream set with new video track and old audio track:", updatedStream);

                // Restart the MediaRecorder with the updated stream
                const newRecorder = new MediaRecorder(updatedStream);
                console.log('Restarting MediaRecorder with updated stream after flip...');

                newRecorder.ondataavailable = (event) => {
                    if (event.data && event.data.size > 0) {
                        console.log('Before push - allChunks length:', allChunksRef.current.length);
                        allChunksRef.current.push(event.data); // Use ref to store chunks across sessions
                        console.log('New chunk received after camera flip, size:', event.data.size);
                        console.log('After push - allChunks length:', allChunksRef.current.length);
                    }
                };

                newRecorder.onstop = () => {
                    const userStopped = isUserStoppedRef.current; // Capture the latest ref value
                    console.log('New recorder stopped. Total chunks:', allChunksRef.current.length);
                    console.log("isUserStopped at onstop for new recorder:", userStopped);
                    
                    // Log each chunk for better visibility
                    allChunksRef.current.forEach((chunk, index) => {
                        console.log(`Chunk ${index + 1}:`, chunk);
                    });
                    
                    if (userStopped) {
                        console.log("User stopped recording. Preparing final video.");
                        if (allChunksRef.current.length > 0) {
                            const blob = createDynamicBlob(allChunksRef.current);
                            const videoUrl = URL.createObjectURL(blob);
                            setDownloadUrl(videoUrl);
                            setPreviewUrl(videoUrl);
                            console.log('Final download URL created:', videoUrl);

                            // Upload the video file after the user stops recording
                            uploadVideo(blob);
                        } else {
                            console.error('No data available to create a final video.');
                        }
                    }
                };                

                newRecorder.start();
                setMediaRecorder(newRecorder);

                // Update the camera facing mode state
                setFacingMode(newFacingMode);
                console.log('Camera flipped successfully and recording resumed.');

            } catch (error) {
                console.error('Error flipping camera:', error);
            }
        }
    };

    const createDynamicBlob = (chunks) => {
        try {
            const mimeType = videoFormat === 'webm' ? 'video/webm' : 'video/mp4';
            
            // Log the number of chunks to ensure all are included in the final blob
            console.log(`Creating blob from chunks. Number of chunks: ${chunks.length}`);
            
            // Attempt to create a blob using all the chunks collected across sessions
            const blob = new Blob(chunks, { type: mimeType });
            
            console.log(`Created blob with type: ${mimeType} and size:`, blob.size);
            return blob;
        } catch (error) {
            console.error("Error creating the video blob from chunks:", error);
        }
    };

    return (
        <div style={{ textAlign: 'center', position: 'relative' }}>
            {!isStreamStarted && (
                <div style={{ height: '300px', width: '100%', backgroundColor: '#ddd', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ color: '#888' }}>Video Stream Placeholder</p>
                </div>
            )}

            {isStreamStarted && (
                <div style={{ position: 'relative' }}>
                    <video
                        ref={videoRef}
                        autoPlay
                        playsInline
                        muted
                        style={{
                            transform: 'scaleX(-1)',
                            width: '100%',
                            maxWidth: '500px',
                        }}
                    />
                    <div style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        width: '20px',
                        height: '20px',
                        borderRadius: '50%',
                        backgroundColor: isRecording ? 'red' : 'gray',
                    }}>
                    </div>
                </div>
            )}

            <div style={{ textAlign: 'center', marginTop: '10px' }}>
                {!isStreamStarted && (
                    <button onClick={() => startCameraStream(facingMode)}>Access Camera</button>
                )}
                {isStreamStarted && !isRecording && (
                    <button onClick={startRecording}>Start Recording</button>
                )}
                {isRecording && (
                    <>
                        <button onClick={pauseRecording}>
                            {isPaused ? 'Resume Recording' : 'Pause Recording'}
                        </button>
                        <button onClick={stopRecording}>Stop Recording</button>
                    </>
                )}
                {cameraAccessed && (
                    <button onClick={flipCamera}>Flip Camera</button>
                )}
            </div>

            {/* Checkbox to select public/private */}
            <div style={{ marginTop: '10px' }}>
                <label>
                    <input 
                        type="checkbox" 
                        checked={isPrivate} 
                        onChange={(e) => setIsPrivate(e.target.checked)} 
                    />
                    Is Private?
                </label>
            </div>

            {/* Show upload progress */}
            {uploadProgressRef.current > 0 && (
                <div style={{ marginTop: '20px' }}>
                    <h3>Uploading Video: {uploadProgressRef.current}%</h3>
                    <progress value={uploadProgressRef.current} max="100"></progress>
                </div>
            )}

            {uploadComplete && (
                <div style={{ marginTop: '20px', color: 'green' }}>
                    <h3>Upload Complete!</h3>
                    <h4>Access Mode: {videoInfo.accessMode}</h4>
                    <a href={videoInfo.url} target='_blank'>URL video</a>
                    <br/>
                    <a href={videoInfo.secureUrl} target='_blank'>SECURE URL video</a>
                </div>
            )}

            {previewUrl && (
                <div style={{ marginTop: '20px', textAlign: 'center' }}>
                    <h3>Preview Recorded Video</h3>
                    <video
                        src={previewUrl}
                        controls
                        playsInline
                        style={{
                            width: '100%',
                            maxWidth: '500px',
                            marginTop: '10px',
                        }}
                    />
                </div>
            )}

            {downloadUrl && (
                <div style={{ marginTop: '20px' }}>
                    <a href={downloadUrl} download={`recorded_video.${videoFormat}`}>
                        Download Video
                    </a>
                </div>
            )}
        </div>
    );
};

export default VideoRecorder;
